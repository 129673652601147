export function EncyclopediaTribe(){
    return (
        <div>
            <h4>
                Tribe
            </h4>
            <p>
                There are 30 tribes in the game. Tribes have primary and second warbands.
                As a player you can join tribe's warband if your overall is enough for
                this team's position. Tribes pay well for warbands.

                

            </p>
        </div>
    )   
}