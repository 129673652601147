import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTribeByNameApi } from "../../services/tribeService";
import { toast } from "react-toastify";

export function TribeProfile() {
  const routeParams = useParams();
  const [tribeInformation, setTribeInformation] = useState({});

  let stars = [1, 2, 3, 4, 5];
  let starVisual = stars.map((starItem) => {
    return (
      <i
        key={starItem}
        className={`bi me-1 ${
          tribeInformation.primaryWarbandOverall >= starItem * 20 - 10 &&
          "bi-star-fill"
        } 
            ${
              tribeInformation.primaryWarbandOverall >= starItem * 20 - 20 &&
              tribeInformation.primaryWarbandOverall < starItem * 20 - 10 &&
              "bi-star-half"
            }
            ${
              tribeInformation.primaryWarbandOverall < starItem * 20 - 20 &&
              "bi-star"
            }`}
      ></i>
    );
  });

  useEffect(() => {
    getTribeByNameApi(routeParams.tribeName).then((data) => {
      if (data.errorCode) {
        if (data.message === "CantFindTribe") {
          setTribeInformation({});
        } else {
          toast.info(data.message, { theme: "colored" });
        }
      } else {
        setTribeInformation(data);
      }
    });
  }, [routeParams.tribeName]);

  let secondaryWarbandPositionCounter = 0;
  let secondaryWarbandTableRows = tribeInformation.secondWarband?.map(
    (warbandPart) => {
      secondaryWarbandPositionCounter++;
      let warbandPartPosition = secondaryWarbandPositionCounter;
      if (warbandPart == null) {
        return (
          <tr key={warbandPartPosition}>
            <th>{warbandPartPosition}</th>
            <td>-</td>
            <td>-</td>
            <td className="text-capitalize">-</td>
          </tr>
        );
      }
      return (
        <tr key={warbandPartPosition}>
          <th>{warbandPartPosition}</th>
          <td className="text-capitalize">{warbandPart.characterName}</td>
          <td>{warbandPart.warriorType}</td>
          <td className="text-capitalize">{warbandPart.overall}</td>
        </tr>
      );
    }
  );

  let primaryWarbandPositionCounter = 0;
  let primaryWarbandTableRows = tribeInformation.firstWarband?.map(
    (warbandPart) => {
      primaryWarbandPositionCounter++;
      let warbandPartPosition = primaryWarbandPositionCounter;
      if (warbandPart == null) {
        return (
          <tr key={warbandPartPosition}>
            <th>{warbandPartPosition}</th>
            <td>-</td>
            <td>-</td>
            <td className="text-capitalize">-</td>
          </tr>
        );
      }
      return (
        <tr key={warbandPartPosition}>
          <th>{warbandPartPosition}</th>
          <td className="text-capitalize">{warbandPart.characterName}</td>
          <td>{warbandPart.warriorType}</td>
          <td className="text-capitalize">{warbandPart.overall}</td>
        </tr>
      );
    }
  );

  let tribeInformationVisual = (
    <div>
      <h3>{tribeInformation.tribeName} Tribe</h3>
      <div>
        <div
          className="d-flex "
          style={{ fontSize: "30px", color: `#${tribeInformation.color}` }}
        >
          {starVisual}
        </div>
        <div className="mt-2 text-capitalize">
          Prestige: {tribeInformation.prestige} <br />
          Warlord: {tribeInformation.warlord?.characterName} <br />
          Primary Warband Overall: {tribeInformation.primaryWarbandOverall}{" "}
          <br />
          Secondary Warband Overall: {tribeInformation.secondaryWarbandOverall}
        </div>

        <div className="mt-5 warband-info-container">
          <h4>
            Primary Warband
            <i
              className="bi bi-octagon-fill ms-2"
              style={{ color: `#${tribeInformation.color}` }}
            ></i>
          </h4>
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Warrior</th>
                  <th scope="col">Warrior Type</th>
                  <th scope="col">Overall</th>
                </tr>
              </thead>
              <tbody>{primaryWarbandTableRows}</tbody>
            </table>
          </div>
        </div>

        <div className="mt-5 warband-info-container">
          <h4>
            Secondary Warband
            <i
              className="bi bi-octagon-fill ms-2"
              style={{ color: `#${tribeInformation.color}` }}
            ></i>
          </h4>
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Warrior</th>
                  <th scope="col">Warrior Type</th>
                  <th scope="col">Overall</th>
                </tr>
              </thead>
              <tbody>{secondaryWarbandTableRows}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

  let emptyTribeVisual = <div>
    <h3>
      Unkown Tribe
    </h3>
    <div>
      <p>
        Can not find this tribe
      </p>
    </div>
  </div>

  return (
    <div>
      {tribeInformation.id ? tribeInformationVisual : emptyTribeVisual}
    </div>
  );
}
