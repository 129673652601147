import { toast } from "react-toastify";

let baseUrl = process.env.REACT_APP_BASE_URL;
let accessToken = localStorage.getItem("accessToken");

export function getAccessToken() {
  accessToken = localStorage.getItem("accessToken");
}

export async function serverRequest(method, url, body) {
  //let token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0ZTk4NGRhMTAyODRlZTc1MTc2MTVkNCIsImVtYWlsIjoiZ2FuZGFsZm1haWwiLCJuYmYiOjE2OTMzOTA0OTYsImV4cCI6MTY5MzQyMDQ5NiwiaXNzIjoiV2FycmlvclRhbGUiLCJhdWQiOiJXYXJyaW9yVGFsZSJ9.GZ7ZcHXfFt7TZASVXO5SzlstIC5BYds_4yKkjGvVYk4`;
  let token = localStorage.getItem("accessToken");

  const requestOptions = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.status == 401) {
      throw new Error("Not Authenticated");
    }
    //console.log(response)
    return response.json();
  });
}

export async function getUserInformationFromApi() {
  let token = localStorage.getItem("accessToken");
  let url = baseUrl + "/User/self";

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.status == 401) {
      throw new Error("Not Authenticated");
    }
    return response.json();
  });
}

export async function getCharacterInformationFromApi() {
  let token = localStorage.getItem("accessToken");
  let url = baseUrl + "/Character";

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.status == 401) {
      throw new Error("Not Authenticated");
    }
    return response.json();
  });
}

export async function changeAvatarApi(avatarName) {
  let token = localStorage.getItem("accessToken");
  let url = baseUrl + "/Character/change-avatar/" + avatarName;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.status == 401) {
      throw new Error("Not Authenticated");
    }
    return response.json();
  });
}

export async function startWorkApi(workName) {
  let url = baseUrl + "/action/work";

  let requestBody = {
    workName: "Farming",
  };

  getAccessToken();

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    body: JSON.stringify(requestBody),
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.status === 401) {
      throw new Error("Not Authenticated");
    }
    return response.json();
  });
}

export async function getGameItemsFromApi() {
  let url = baseUrl + "/game/game-items";

  getAccessToken();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.status === 401) {
      throw new Error("Not Authenticated");
    }
    return response.json();
  });
}

export async function postMarketBuyApi(itemId, quantity) {
  let url = baseUrl + "/action/market";

  let requestBody = {
    itemId: itemId,
    quantity: quantity,
  };

  getAccessToken();

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    body: JSON.stringify(requestBody),
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.status === 401) {
      throw new Error("Not Authenticated");
    }
    return response.json();
  });
}

export async function actionItemApi(itemId) {
  let url = baseUrl + "/action/use-item";

  let requestBody = {
    itemId: itemId,
  };

  getAccessToken();

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    body: JSON.stringify(requestBody),
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.status === 401) {
      throw new Error("Not Authenticated");
    }
    return response.json();
  });
}

export async function trainSkillApi(trainName) {
  let url = baseUrl + "/action/train";

  let requestBody = {
    trainName: trainName,
  };

  getAccessToken();

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    body: JSON.stringify(requestBody),
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.status === 401) {
      throw new Error("Not Authenticated");
    }
    return response.json();
  });
}

export async function getAllCharacterApi() {
  let url = baseUrl + "/character/all";

  getAccessToken();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.status === 401) {
      throw new Error("Not Authenticated");
    }
    return response.json();
  }).catch((error) => {
    if (error.message === "Failed to fetch") {
      toast.info("Can not connect server", { theme: "colored" });
    }
  });
}

export async function getAllWarlordsApi() {
  let url = baseUrl + "/character/warlords";

  getAccessToken();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  };

  return fetch(url, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        throw new Error("Not Authenticated");
      }
      return response.json();
    })
    .catch((error) => {
      if (error.message === "Failed to fetch") {
        toast.info("Can not connect server", { theme: "colored" });
      }
    });
}

export async function getTribesApi() {
  let url = baseUrl + "/tribe/all";

  getAccessToken();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  };

  return fetch(url, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        throw new Error("Not Authenticated");
      }
      return response.json();
    })
    .catch((error) => {
      if (error.message === "Failed to fetch") {
        toast.info("Can not connect server", { theme: "colored" });
      }
    });
}

export async function getTribesMediumApi() {
  let url = baseUrl + "/tribe/all-medium";

  getAccessToken();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.status === 401) {
      throw new Error("Not Authenticated");
    }
    return response.json();
  });
}

export async function postWarbandPlanApi(warbandPlanParts, warbandOrder) {
  let url = baseUrl + "/tribe/warband-plan";

  getAccessToken();

  let requestBody = {
    warbandOrder: warbandOrder,
    warbandPlanParts: warbandPlanParts,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    body: JSON.stringify(requestBody),
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.status === 401) {
      throw new Error("Not Authenticated");
    }
    return response.json();
  });
}

export async function getWarbandAdvertisementApi() {
  let url = baseUrl + "/tribe/warband-advertisement";

  getAccessToken();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.status === 401) {
      throw new Error("Not Authenticated");
    }
    return response.json();
  });
}

export async function postApplyAdvertisementApi(advertisementId) {
  let url = baseUrl + "/tribe/apply-advertisement";

  let requestBody = {
    advertisementId: advertisementId,
  };

  getAccessToken();

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    body: JSON.stringify(requestBody),
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.status === 401) {
      throw new Error("Not Authenticated");
    }
    return response.json();
  });
}

export async function getEventsApi(targetPage) {
  let url = baseUrl + "/character/events/" + targetPage;

  getAccessToken();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  };

  return fetch(url, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        throw new Error("Not Authenticated");
      }
      return response.json();
    })
    .catch((error) => {
      if (error.message === "Failed to fetch") {
        toast.info("Can not connect server", { theme: "colored" });
      }
    });
}

export async function getLadyApi() {
  let url = baseUrl + "/game/lady";

  getAccessToken();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  };

  return fetch(url, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        throw new Error("Not Authenticated");
      }
      return response.json();
    })
    .catch((error) => {
      if (error.message === "Failed to fetch") {
        toast.info("Can not connect server", { theme: "colored" });
      }
    });
}

export async function postMarryApi(wifeId) {
  let url = baseUrl + "/character/marry/" + wifeId;

  let requestBody = {};

  getAccessToken();

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    body: JSON.stringify(requestBody),
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.status === 401) {
      throw new Error("Not Authenticated");
    }
    return response.json();
  });
}

export async function postChangeWarriorTypeApi(warriorType) {
  let url = baseUrl + "/character/warrior-type/" + warriorType;

  let requestBody = {};

  getAccessToken();

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    body: JSON.stringify(requestBody),
  };

  return fetch(url, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        throw new Error("Not Authenticated");
      }
      return response.json();
    })
    .catch((error) => {
      if (error.message === "Failed to fetch") {
        toast.info("Can not connect server", { theme: "colored" });
      }
    });
}

export function showLoadingSpinner() {
  document.getElementById("loadingWindow")?.classList.remove("hide-window");
  document.getElementById("loadingWindow")?.classList.add("show-window");
}

export function hideLoadingSpinner() {
  document.getElementById("loadingWindow")?.classList.remove("show-window");
  document.getElementById("loadingWindow")?.classList.add("hide-window");
}
