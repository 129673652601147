import { useEffect, useState } from "react";
import { GameMediumContainer } from "../General/GameMediumContainer";
import { getTribesApi } from "../../services/warriorService";

export function Tribes(props) {
  const [tribes, setTribes] = useState([]);

  useEffect(() => {
    console.log("change meta");
    document.querySelector('meta[property="og:title"]').setAttribute("content", "Warrior Tale - Tribes");
    document.querySelector('meta[property="og:image"]').setAttribute("content", "https://warriortale.com/images/general/warlord.png");
  },[])

  useEffect(() => {
    getTribesApi().then((data) => {
      if(data){
        setTribes(data);
        document.querySelector('meta[property="og:title"]').setAttribute("content", "Warrior Tale - Tribe Informations");
      }
      
    });
  }, []);

  let rankingCounter = 0;
  let tribesTableRows = tribes.map((tribe) => {
    rankingCounter++;
    return (
      <tr key={tribe.id}>
        <th scope="row">{rankingCounter}</th>
        <td className="text-capitalize">
          <div className="d-flex align-items-center">
            <div>
              <span>
                <i
                  className="bi bi-octagon-fill"
                  style={{ color: `#${tribe.color}` }}
                ></i>
              </span>
            </div>
            <div className="ms-3">{tribe.tribeName}</div>
          </div>
        </td>
        <td className="general-table-td">{tribe.prestige}</td>
        <td className="general-table-td text-capitalize">
            {tribe.warlordId === 'npc' ? "Npc" : tribe.warlordInfo?.warlordName }
        </td>
      </tr>
    );
  });

  return (
    <div>
      <GameMediumContainer innerContainer={props.innerContainer}>
        <h2>Tribes</h2>
        <div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Tribe</th>
                <th scope="col">Prestige</th>
                <th scope="col">Warlord</th>
              </tr>
            </thead>
            <tbody>{tribesTableRows}</tbody>
          </table>
        </div>
      </GameMediumContainer>
    </div>
  );
}
