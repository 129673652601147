export function EncyclopediaPrestige(){
    return (
        <div>
            <h4>
                Prestige
            </h4>
            <p>
            There are two kinds of prestige in warrior tale. One is warrior prestige, the other is warlord prestige. Warlord prestige may gain or lose after tribe’s battles. Warlord prestige will be available only character is warlord. Warlord prestige doesn’t decrease after losing battles like warrior prestige, but warlord prestige is important for warlords ranking.
            </p>
        </div>
    )   
}