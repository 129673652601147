import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { changeAvatarApi } from "../../services/warriorService";
import CharacterContext from "../../store/CharacterContext";

export const ProfileImage = () => {
  const navigate = useNavigate();
  const characterContext = useContext(CharacterContext);
  const avatarImages = [
    "warrior1",
    "warrior2",
    "warrior3",
    "warrior4",
    "warrior5",
    "warrior6",
    "warrior7",
    "warrior8",
    "warrior9",
    "warrior10",
    "warrior11",
    "warrior12",
    "warrior13",
    "warrior14",
    "warrior15",
    "warrior16",
    "warrior17",
    "warrior18",
    "warrior19",
    "warrior20",
  ];

  const warriorImagesVisual = avatarImages.map((item) => {
    return (
      <div key={item} className="text-center mb-3">       
        <img
          src={"images/character/" + item + ".png"}
          alt="warrior profile"
          width="100%"
        />
        <button className="btn btn-dark mt-1" onClick={() => changeAvatar(item)} >Select</button>
      </div>
    );
  });

  const changeAvatar = (targetAvatar) => {
    changeAvatarApi(targetAvatar).then((data) => {
      characterContext.setNewValue(data);
      navigate('/character')
    })

  }

  return (
    <div>
      <h3 onClick={changeAvatar} >Select Avatar</h3>

      <div className="game-grid">{warriorImagesVisual}</div>
    </div>
  );
};

//export default ProfileImage;
