export function HomeInfoCard(props) {
  return (
    <div className="home-info-container d-flex align-items-center ">
      <div className="home-info-image-container">
        <img
          src={props.cardInfo.image}
          alt="warrior"
          className="warrior-tale-image"
        />
      </div>
      <div className="home-info-text ms-3">
        <h3>
            {props.cardInfo.title}
        </h3>
        <p>
        {props.cardInfo.content}
        </p>
      </div>
    </div>
  );
}
