import { useEffect, useState } from "react";
import "./Warriors.css";
import { getAllCharacterApi } from "../../services/warriorService";
import { toast } from "react-toastify";

export function Warriors(props) {
  const [warriors, setWarriors] = useState([]);

  useEffect(() => {
    getAllCharacterApi().then((data) => {
      if(data){
        setWarriors(data);
      }   
    }).catch((error) => {
      if (error.message === "Failed to fetch") {
        toast.info("Can not connect server", { theme: "colored" });
      }
    });
  }, []);

  let rankingCounter = 0;
  let warriorTableRows = warriors.map((warrior) => {
    rankingCounter++;
    return (
      <tr key={warrior.id}>
        <th scope="row">{rankingCounter}</th>
        <td className="text-capitalize">
          <div className="d-flex align-items-center" >
            <div>
              <img
                width="60px"
                className="game-menu-image warrior-rank-image"
                src={`images/character/${warrior.avatar}.png`}
                alt="warrior portrait"
              />
            </div>
            <div className="ms-3" >{warrior.characterName}</div>
          </div>
        </td>
        <td className="general-table-td" >{warrior.overall}</td>
        <td className="general-table-td" >{warrior.warriorType}</td>
      </tr>
    );
  });

  return (
    <div className={`general-game-container mx-auto p-3 ${props.innerContainer ? 'inner-container ' : 'mt-3 '} `  }  >
      <h2>Warriors</h2>
      <div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Warrior</th>
              <th scope="col">Overall</th>
              <th scope="col">Warrior Type</th>
            </tr>
          </thead>
          <tbody>{warriorTableRows}</tbody>
        </table>
      </div>
    </div>
  );
}
