import { Routes, Route } from "react-router-dom";
import { Character } from "../Character/Character";
import { Home } from "./Home";
import { Register } from "../Authentication/Register";
import { Login } from "../Authentication/Login";
import { Warriors } from "./Warriors";
import { Life } from "../Life/Life";
import { Encyclopedia } from "./Encyclopedia";
import { Warlords } from "../InformationMenus/Warlords";
import { Tribes } from "../InformationMenus/Tribes";
import { GameInformation } from "../InformationMenus/GameInformation";
import { EncyclopediaWarrior } from "../Encyclopedia/EncyclopediaWarrior";
import { EncyclopediaOverall } from "../Encyclopedia/EncyclopediaOverall";
import { EncyclopediaTribe } from "../Encyclopedia/EncyclopediaTribe";
import { EncyclopediaWarlord } from "../Encyclopedia/EncyclopediaWarlord";
import { EncyclopediaBattle } from "../Encyclopedia/EncyclopediaBattle";
import { EncyclopediaPrestige } from "../Encyclopedia/EncyclopediaPrestige";

export const Welcome = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/warriors" element={<Warriors />}></Route>
        <Route path="/tribes" element={<Tribes />}></Route>
        <Route path="/warlords" element={<Warlords />}></Route>
        <Route path="/info" element={<GameInformation />}></Route>

        <Route path="/game-encylopedia" element={<Encyclopedia />}>
          <Route path="warrior" element={<EncyclopediaWarrior />}></Route>
          <Route path="warrior-overall" element={<EncyclopediaOverall />}></Route>
          <Route path="tribe" element={<EncyclopediaTribe />}></Route>
          <Route path="warlord" element={<EncyclopediaWarlord />}></Route>
          <Route path="battles" element={<EncyclopediaBattle />}></Route>
          <Route path="prestige" element={<EncyclopediaPrestige />}></Route>
          <Route path="*" element={<EncyclopediaWarrior />}></Route>
        </Route>

        <Route path="/character" element={<Character />}></Route>
        <Route path="/life" element={<Life />}></Route>

        <Route path="*" element={<Home />} />

      </Routes>
    </>
  );
};
