import { useContext } from 'react';
import './Training.css'
import CharacterContext from '../../store/CharacterContext';
import { trainSkillApi } from '../../services/warriorService';
import { toast } from "react-toastify";

export function Training() {
  const characterContext = useContext(CharacterContext);
  // let skills = [
  //   { name: "strength", value: 70 },
  //   { name: "handle", value: 75 },
  //   { name: "mental", value: 90 },
  // ];

  let skills = [
    { name: "Strength", value: characterContext.characterSkillTree.strength },
    { name: "Handle", value: characterContext.characterSkillTree.handle },
    { name: "Mental", value: characterContext.characterSkillTree.mental },

    { name: "Endurance", value: characterContext.characterSkillTree.endurance },
    { name: "Defense", value: characterContext.characterSkillTree.defence },
    { name: "Blocks", value: characterContext.characterSkillTree.blocks },
    { name: "Shield", value: characterContext.characterSkillTree.shield },

    { name: "Speed", value: characterContext.characterSkillTree.speed },
    { name: "Attack", value: characterContext.characterSkillTree.attack },
    { name: "Pierce", value: characterContext.characterSkillTree.pierce },
    { name: "Charge", value: characterContext.characterSkillTree.charge },
  ];

  function trainSkill(skillCode){
    trainSkillApi(skillCode).then((data) => {
      if (data.errorCode) {
        toast.info(data.message, { theme: "colored" });
      } else {
        toast.success("Training", { theme: "colored" });
        characterContext.setNewValue(data);
      }
    })
  }

  let skillTableRows = skills.map((skill) => {
    return (
        <tr key={skill.name} >
          <td className="text-capitalize" >{skill.name}</td>
          <td>{skill.value}</td>
          <td>
            <button className="btn btn-dark" onClick={() => trainSkill(skill.name)} >
                Train
            </button>
          </td>
        </tr>
    );
  });

  return (
    <>
      <table className="warrior-table mt-3" >
        <thead>
          <tr>
            <th scope="col">Skill</th>
            <th scope="col">Value</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>

          {skillTableRows}


        </tbody>
      </table>
    </>
  );
}
