export function EncyclopediaWarlord(){
    return (
        <div>
            <h4>
                Warlord
            </h4>
            <p>
            Warriors can be warlords of the tribes. Need to claim for warlord before new months first day, every month first day warlords selected. Highest overall warriors become warlords. Warlords design their warbands for tribes and gain warlord prestige.

            </p>
        </div>
    )   
}