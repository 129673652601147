export function EncyclopediaOverall(){
    return (
        <div>
            <h4>
                Warrior Overall
            </h4>
            <p>
                Warrior overall calculated based on warriors' skills and warrior type.
                Warrior types are balanced, defense and attack. Every type's overall 
                calculated differently.

                Balanced type calculated by avarage of
                strength, handle, mental, attack, defense, endurance, speed.

                Defense type calculated by avarage of
                strength, handle, mental, defense, endurance, shield, block.

                Attack type calculated by avarage of
                strength, handle, mental, attack, speed, pierce, charge.

                

            </p>
        </div>
    )   
}