import { Link, Outlet } from "react-router-dom";

export function Encyclopedia(props) {
  return (
    <div
      className={`general-game-container mx-auto p-3 ${
        props.innerContainer ? "inner-container " : "mt-3 "
      } `}
    >
      <h2>Encyclopedia</h2>
      <div className="row">
        <div className="col-md-3">
          <div className="menu-navigator">
            <nav>
              <ul>
                <Link to="warrior" className="non-decorated-link">
                  <li className="menu-navigator-item">Warrior</li>
                </Link>

                <Link to="warrior-overall" className="non-decorated-link">
                  <li className="menu-navigator-item">Warrior Overall</li>
                </Link>

                <Link to="tribe" className="non-decorated-link">
                  <li className="menu-navigator-item">Tribe</li>
                </Link>

                <Link to="warlord" className="non-decorated-link">
                  <li className="menu-navigator-item">Warlord</li>
                </Link>

                <Link to="battles" className="non-decorated-link">
                  <li className="menu-navigator-item">Battles</li>
                </Link>

                <Link to="prestige" className="non-decorated-link">
                  <li className="menu-navigator-item">Prestige</li>
                </Link>
              </ul>
            </nav>
          </div>
        </div>

        <div className="col-md-9">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
