import { createContext, useEffect, useState } from "react";
import { getUserInformationFromApi } from "../services/warriorService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const UserContext = createContext({
  userName: "Guest",
  userId: '0',
  isAuthenticated: false,
  logout: () => {},
  refresh: () => {},
});



export const UserContextProvider = (props) => {
  const navigate = useNavigate();

  const logoutUser = () => {
    localStorage.removeItem('accessToken');
    setUserInformation((prevState) => {
        return {
            ...prevState,
            isAuthenticated: false
        }
    });
    navigate('/')
  };

  const refreshUserInformation = () => {
    let token = localStorage.getItem('accessToken')
    if(token){
      getUserInformationFromApi().then((data) => {
        setUserInformation((prevState) => {
          return {
            ...prevState,
            id: data.id,
            userName: data.userName,
            isAuthenticated: true
          }
        })
      })
    }else{
      
    }
  }


  const defaultUserValue = {
    userName: "Guest",
    id: '0',
    isAuthenticated: false,
    logout: logoutUser,
    refresh: refreshUserInformation
  };

  const [userInformation, setUserInformation] = useState(defaultUserValue);
  const [refreshCounter, setRefreshCounter] = useState(0)

  
  

  useEffect(() => {
    let token = localStorage.getItem('accessToken')
    if(token){
      getUserInformationFromApi().then((data) => {
        setUserInformation((prevState) => {
          return {
            ...prevState,
            id: data.id,
            userName: data.userName,
            isAuthenticated: true
          }
        })
      }).catch((error) => {
        console.log(error)
        if(error.message === "Failed to fetch"){
          toast.info("Can not connect server", {theme: "colored"})
        }
      })
    }else{
      
    }
    
  }, []);

  return (
    <UserContext.Provider value={userInformation}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
