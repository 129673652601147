import { useContext, useState } from "react";
import { postChangeWarriorTypeApi } from "../../services/warriorService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CharacterContext from "../../store/CharacterContext";

export function WarriorType() {
  const [warriorType, setWarriorType] = useState(null);
  const navigate = useNavigate();
  const characterContext = useContext(CharacterContext);

  function changeWarriorType() {
    if (warriorType) {
      postChangeWarriorTypeApi(warriorType).then((data) => {
        if (data.errorCode) {
          toast.info(data.message, { theme: "colored" });
        } else {
          characterContext.setNewValue(data);
          toast.success("Changed", { theme: "colored" });
          navigate("/character");
        }
      });
    } else {
      toast.info("Warrior type not selected", { theme: "colored" });
    }
  }

  function warriorTypeChangeHandler(event) {
    let value = event.target.value;
    setWarriorType(value);
  }

  return (
    <div>
      <h3>Warrior Type</h3>

      <div>
        <div className="form-check mb-2">
          <input
            className="form-check-input"
            type="radio"
            name="warriorType"
            id="warriorTypeBalanced"
            value="Balanced"
            onChange={warriorTypeChangeHandler}
          />
          <label className="form-check-label" htmlFor="warriorTypeBalanced">
            Balanced
          </label>
        </div>
        <div className="form-check mb-2">
          <input
            className="form-check-input"
            type="radio"
            name="warriorType"
            id="warriorTypeDefence"
            value="Defence"
            onChange={warriorTypeChangeHandler}
          />
          <label className="form-check-label" htmlFor="warriorTypeDefence">
            Defence
          </label>
        </div>
        <div className="form-check mb-2">
          <input
            className="form-check-input"
            type="radio"
            name="warriorType"
            id="warriorTypeAttack"
            value="Attack"
            onChange={warriorTypeChangeHandler}
          />
          <label className="form-check-label" htmlFor="warriorTypeAttack">
            Attack
          </label>
        </div>
        <button className="btn btn-dark mt-2" onClick={changeWarriorType}>
          Change Warrior Type
        </button>
      </div>
    </div>
  );
}
