import './MenuNavigator.css'
import { Link } from 'react-router-dom'

const MenuNavigator = () => {
  return (
    <div className="menu-navigator mt-5 mb-5">
      <nav>
        <ul>
          <Link to="/character" className="non-decorated-link">
            <li className="menu-navigator-item">Character</li>
          </Link>

          <Link to="/life" className="non-decorated-link">
            <li className="menu-navigator-item">Life</li>
          </Link>

          <Link to="/inventory" className="non-decorated-link">
            <li className="menu-navigator-item">Inventory</li>
          </Link>

          <Link to="/events" className="non-decorated-link">
            <li className="menu-navigator-item">Events</li>
          </Link>

          <Link to="/tribe" className="non-decorated-link">
            <li className="menu-navigator-item">Tribe</li>
          </Link>

          <Link to="/market" className="non-decorated-link">
            <li className="menu-navigator-item">Market</li>
          </Link>


          <Link to="/warlord" className="non-decorated-link">
            <li className="menu-navigator-item">Warlord</li>
          </Link>








        </ul>
      </nav>
    </div>
  );
};

export default MenuNavigator;
