import { Character } from "../Character/Character";
import { Footer } from "../Footer";
import { Inventory } from "../Inventory/Inventory";
import { CharacterInfo } from "../CharacterInfo/CharacterInfo";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MenuNavigator from "../MenuNavigator/MenuNavigator";
import { Life } from "../Life/Life";
import { EventsMenu } from "../EventsMenu/EventsMenu";
import { ProfileImage } from "../Character/ProfileImage";
import { Tribe } from "../Tribe/Tribe";
import { Market } from "../Market/Market";
import { Wife } from "../Life/Wife";
import { useContext } from "react";
import CharacterContext from "../../store/CharacterContext";
import { Warriors } from "./Warriors";
import { Warlord } from "../Tribe/Warlord";
import { TribeManage } from "../Tribe/TribeManage";
import { TribeProfile } from "../Tribe/TribeProfile";
import { Pet } from "../Life/Pet";
import { WarriorType } from "../Character/WarriorType";
import { Warlords } from "../InformationMenus/Warlords";
import { Tribes } from "../InformationMenus/Tribes";
import { Encyclopedia } from "./Encyclopedia";
import { EncyclopediaWarrior } from "../Encyclopedia/EncyclopediaWarrior";
import { EncyclopediaOverall } from "../Encyclopedia/EncyclopediaOverall";
import { EncyclopediaTribe } from "../Encyclopedia/EncyclopediaTribe";
import { EncyclopediaWarlord } from "../Encyclopedia/EncyclopediaWarlord";
import { EncyclopediaBattle } from "../Encyclopedia/EncyclopediaBattle";
import { EncyclopediaPrestige } from "../Encyclopedia/EncyclopediaPrestige";
import { GameInformation } from "../InformationMenus/GameInformation";

export function Game() {
  const characterContext = useContext(CharacterContext);

  return (
    <div className="container-fluid mt-3">
      <div className="row">
        <div className="col-md-3">
          <CharacterInfo />
          <MenuNavigator />
        </div>
        <div className="col-md-9">
          <div
            className="game-menu-container"
            style={{ backgroundColor: "#dad8e3", color: "black" }}
          >
            <Routes>
              <Route path="/" element={<Character />}></Route>
              <Route path="/character" element={<Character />}></Route>
              <Route path="/life" element={<Life />}></Route>
              <Route path="/inventory" element={<Inventory />}></Route>
              <Route path="/events" element={<EventsMenu />}></Route>
              <Route path="/tribe" element={<Tribe />}></Route>
              <Route path="/market" element={<Market />}></Route>
              <Route path="/warlord" element={<Warlord />}></Route>

              <Route path="/about" element={<Inventory />}></Route>
              <Route path="/contact" element={<Footer />}></Route>

              <Route path="/wife" element={<Wife />}></Route>
              <Route path="/pet" element={<Pet />}></Route>
              <Route path="/character-image" element={<ProfileImage />}></Route>
              <Route path="/warrior-type" element={<WarriorType />}></Route>
              <Route path="/tribe-manage" element={<TribeManage />}></Route>


              <Route path="/info" element={<GameInformation innerContainer={true} />}></Route>

              <Route
                path="/tribe/:tribeName"
                element={<TribeProfile />}
              ></Route>

              <Route
                path="/warriors"
                element={<Warriors innerContainer={true} />}
              ></Route>
              <Route
                path="/tribes"
                element={<Tribes innerContainer={true} />}
              ></Route>
              <Route
                path="/warlords"
                element={<Warlords innerContainer={true} />}
              ></Route>

              <Route path="/game-encylopedia" element={<Encyclopedia innerContainer={true}  />}>
                <Route path="warrior" element={<EncyclopediaWarrior />}></Route>
                <Route
                  path="warrior-overall"
                  element={<EncyclopediaOverall />}
                ></Route>
                <Route path="tribe" element={<EncyclopediaTribe />}></Route>
                <Route path="warlord" element={<EncyclopediaWarlord />}></Route>
                <Route path="battles" element={<EncyclopediaBattle />}></Route>
                <Route
                  path="prestige"
                  element={<EncyclopediaPrestige />}
                ></Route>
                <Route path="*" element={<EncyclopediaWarrior />}></Route>
              </Route>

              <Route path="*" element={<Character />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}
