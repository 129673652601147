import { useContext, useEffect } from "react";
import "./Character.css";
import { Link, redirect, useNavigate } from "react-router-dom";
import CharacterContext from "../../store/CharacterContext";
import UserContext from "../../store/UserContext";

export function Character() {
  const characterContext = useContext(CharacterContext);
  const userContext = useContext(UserContext);
  const navigate = useNavigate();


  function tokenControlForProtectedPage(){
    let token = localStorage.getItem('accessToken');
    if(!token){
      return navigate('/login');
    }
    return false;
  }
  useEffect(() => {
    tokenControlForProtectedPage();
  })

  

  let stars = [1, 2, 3, 4, 5];

  // let skills = [
  //   { name: "strength", value: characterContext.characterSkillTree.strength },
  //   { name: "handle", value: 75 },
  //   { name: "mental", value: 90 },

  //   { name: "Endurance", value: 24 },
  //   { name: "Defence", value: 36 },
  //   { name: "Blocks", value: 81 },
  //   { name: "Shield", value: 56 },

  //   { name: "Speed", value: 62 },
  //   { name: "Attack", value: 64 },
  //   { name: "Pierce", value: 56 },
  //   { name: "Charge", value: 56 },
  // ];

  let skills = [
    { name: "Strength", value: characterContext.characterSkillTree.strength },
    { name: "Handle", value: characterContext.characterSkillTree.handle },
    { name: "Mental", value: characterContext.characterSkillTree.mental },

    { name: "Endurance", value: characterContext.characterSkillTree.endurance },
    { name: "Defense", value: characterContext.characterSkillTree.defence },
    { name: "Blocks", value: characterContext.characterSkillTree.blocks },
    { name: "Shield", value: characterContext.characterSkillTree.shield },

    { name: "Speed", value: characterContext.characterSkillTree.speed },
    { name: "Attack", value: characterContext.characterSkillTree.attack },
    { name: "Pierce", value: characterContext.characterSkillTree.pierce },
    { name: "Charge", value: characterContext.characterSkillTree.charge },
  ];

  function changeSkillOrder() {
    if (characterContext.warriorType === "Balanced") {
      skills = [
        {
          name: "Strength",
          value: characterContext.characterSkillTree.strength,
        },
        { name: "Handle", value: characterContext.characterSkillTree.handle },
        { name: "Mental", value: characterContext.characterSkillTree.mental },

        { name: "Attack", value: characterContext.characterSkillTree.attack },
        { name: "Defense", value: characterContext.characterSkillTree.defence },
        {
          name: "Endurance",
          value: characterContext.characterSkillTree.endurance,
        },
        { name: "Speed", value: characterContext.characterSkillTree.speed },

        { name: "Blocks", value: characterContext.characterSkillTree.blocks },
        { name: "Shield", value: characterContext.characterSkillTree.shield },
        { name: "Pierce", value: characterContext.characterSkillTree.pierce },
        { name: "Charge", value: characterContext.characterSkillTree.charge },
      ];
    } else if (characterContext.warriorType === "Attack") {
      skills = [
        {
          name: "Strength",
          value: characterContext.characterSkillTree.strength,
        },
        { name: "Handle", value: characterContext.characterSkillTree.handle },
        { name: "Mental", value: characterContext.characterSkillTree.mental },

        { name: "Speed", value: characterContext.characterSkillTree.speed },
        { name: "Attack", value: characterContext.characterSkillTree.attack },
        { name: "Pierce", value: characterContext.characterSkillTree.pierce },
        { name: "Charge", value: characterContext.characterSkillTree.charge },

        {
          name: "Endurance",
          value: characterContext.characterSkillTree.endurance,
        },
        { name: "Defense", value: characterContext.characterSkillTree.defence },
        { name: "Blocks", value: characterContext.characterSkillTree.blocks },
        { name: "Shield", value: characterContext.characterSkillTree.shield },
      ];
    }
  }

  changeSkillOrder();

  let skillsVisual = skills.map((skill) => {
    return (
      <div key={skill.name}>
        <div className="text-capitalize">{skill.name}</div>

        <div>
          <div className="bar-back">
            <div
              className="bar-fill"
              style={{ width: Math.round((skill.value / 100) * 500) | 0 }}
            ></div>
          </div>
        </div>
      </div>
    );
  });

  let starVisual = stars.map((starItem) => {
    return (
      <i
        key={starItem}
        className={`bi me-1 ${
          characterContext.overall >= starItem * 20 - 10 && "bi-star-fill"
        } 
            ${
              characterContext.overall >= starItem * 20 - 20 &&
              characterContext.overall < starItem * 20 - 10 &&
              "bi-star-half"
            }
            ${characterContext.overall < starItem * 20 - 20 && "bi-star"}`}
      ></i>
    );
  });

  const warriorRankTitle = () => {
    if (characterContext.overall > 80) {
      return "Legend";
    } else if (characterContext.overall > 60) {
      return "Elite Champion";
    } else if (characterContext.overall > 40) {
      return "Champion";
    } else if (characterContext.overall > 20) {
      return "Soldier";
    } else {
      return "Recruit";
    }
  };

  function openChangeWarriorTypeModal(){
    navigate('/warrior-type');
  }



  return (
    <div>
      <div>
        <div className="">
          <div className="fs-3 fw-bold">
            {warriorRankTitle()} ({characterContext.overall})
          </div>
          <div
            className="d-flex "
            style={{ fontSize: "30px", color: "#271d62" }}
          >
            {starVisual}
          </div>
        </div>

        <div>
          Name:{" "}
          <span className="text-capitalize">
            {characterContext.characterName}
          </span>{" "}
          <br />
          Overall: {characterContext.overall} <br />
          Money: {characterContext.money} <br />
          Warrior Type: {characterContext.warriorType} <button onClick={openChangeWarriorTypeModal} className="btn btn-dark ms-2" >Change</button> <br />
          Prestige: {characterContext.prestige} <br />
          {characterContext.warlordCharacter
            ? `Warlord Prestige: ${characterContext.warlordPrestige}`
            : ""}
        </div>

        <div>
          <Link to="/character-image">
            <button className="btn btn-dark mt-2">Change Profile Image</button>
          </Link>
        </div>

        <div className="mt-4 mb-5 skill-group-container">
          <h4>Skills</h4>
          {skillsVisual}
        </div>
      </div>
    </div>
  );
}
