import { useContext, useEffect, useState } from "react";
import "./Market.css";
import { getGameItemsFromApi, postMarketBuyApi } from "../../services/warriorService";
import { toast } from "react-toastify";
import CharacterContext from "../../store/CharacterContext";

const defaultMarketItems = [
  { id: "w", gameItemName: "bread", price: 5, avatar: "bread", itemStatus: 1 },
  { id: "w", gameItemName: "vegetable", price: 5, avatar: "vegetable", itemStatus: 1 },
  { id: "w", gameItemName: "fish", price: 5, avatar: "fish", itemStatus: 1 },
]

export function Market() {
  const [marketItems, setMarketItems] = useState(defaultMarketItems)
  const characterContext = useContext(CharacterContext);


  useEffect(() => {
    getGameItemsFromApi().then((data) => {
      setMarketItems(data);
    }).catch((error) => {
      if(error.message === "Failed to fetch"){
        toast.info("Can not connect server", {theme: "colored"})
      }
    })

  }, []);




  function buyItem(itemId){
    let quantityInputId = 'quantityInput' + itemId;
    let selectedQuantity = document.getElementById(quantityInputId).value;
    postMarketBuyApi(itemId, selectedQuantity).then((data) => {
      if (data.errorCode) {
        toast.info(data.message, { theme: "colored" });
      } else {
        toast.success("Purchased", { theme: "colored" });
        characterContext.refreshCharacterData();
      }
    })


  }

  let marketTableRows = marketItems.map((item) => {
    return (
      <tr key={item.gameItemName} >
        <td className="imageTablePart">
          <img
            src={"images/item/" + item.avatar + ".png"}
            className="market-image"
            alt="gameItemName"
            width="100px"
          />
        </td>
        <td className="text-capitalize">{item.gameItemName}</td>
        <td>{item.itemStatus === 1 ? item.price : '-'}</td>
        <td>
          <div className="d-flex align-items-center justify-content-center market-input-group" >
            <input id={'quantityInput' + item.id} disabled={item.itemStatus !== 1 ? 'disabled' : ''}
              className="form-control market-quantity-input" type="number" defaultValue={1}  />
            <button className="btn btn-dark ms-2" disabled={item.itemStatus !== 1 ? 'disabled' : ''}
              onClick={() => buyItem(item.id)}  >
              Buy
              </button>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div>
      <h3>Market</h3>

      <div>
        <table className="table table-bordered border-secondary market-table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Item</th>
              <th scope="col">Price</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr> */}
            {marketTableRows}
          </tbody>
        </table>

        {/* {marketGoodsVisual} */}
      </div>
    </div>
  );
}
