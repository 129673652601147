import { createContext, useContext, useEffect, useState } from "react";
import UserContext from "./UserContext";
import { getCharacterInformationFromApi, serverRequest } from "../services/warriorService";

const CharacterContext = createContext({
  id: '0',
  characterName: "Default",
  avatar: 'warrior1',
  level: 0,
  levelUp: () => {},
  refreshCharacterData: () => {},
  setNewValue: () => {}
});

export const CharacterContextProvider = (props) => {
  const userContext = useContext(UserContext);

  function refreshCharacterDataHandler(logout = false){
    if(logout === false){
      getCharacterInformationFromApi().then((data) => {
        setCharacterInformation((prevState) => {
            return {
                ...prevState,
                ...data
            }
        })
      })
    }else{
      setCharacterInformation(defaultCharacterValue);
    }
    
  }

  function setNewValueHandler(newData){
    setCharacterInformation((prevState) => {
        return {
            ...prevState,
            ...newData
        }
    })
  }

  const defaultCharacterValue = {
    id: '0',
    characterName: "Warrior",
    avatar: 'warrior1',
    level: "0",
    levelUp: () => {},
    characterSkillTree : {
        strength : 0
    },
    refreshCharacterData: refreshCharacterDataHandler,
    setNewValue: setNewValueHandler
  };

  const [characterInformation, setCharacterInformation] = useState(
    defaultCharacterValue
  );

  

  useEffect(() => {
    if (userContext.isAuthenticated) {
      getCharacterInformationFromApi().then((data) => {
        setCharacterInformation((prevState) => {
            return {
                ...prevState,
                ...data
            }
        })
      })
    }else{
        //not authenticated
    }
  }, [userContext.isAuthenticated]);

  return (
    <CharacterContext.Provider value={characterInformation}>
      {props.children}
    </CharacterContext.Provider>
  );
};

export default CharacterContext;
