import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Register.css";
import { toast } from "react-toastify";
import UserContext from "../../store/UserContext";

export function Register() {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const [registerForm, setRegisterForm] = useState({
    email: { valid: false, value: "", touched: false },
    userName: { valid: false, value: "", touched: false },
    password: { valid: false, value: "", touched: false },
    passwordConfirm: {
      valid: false,
      value: "",
      touched: false,
      passwordMatch: false,
    },
  });

  function registerFormChangeHandler(field, input) {
    let validity = input.length > 2 ? true : false;
    setRegisterForm((prevState) => {
      return {
        ...prevState,
        [field]: { ...prevState[field], value: input, valid: validity },
      };
    });

    if (field === "passwordConfirm") {
      setRegisterForm((prevState) => {
        let passwordMatchControl =
          prevState.password.value === prevState.passwordConfirm.value;
        return {
          ...prevState,
          passwordConfirm: {
            ...prevState.passwordConfirm,
            passwordMatch: passwordMatchControl,
          },
        };
      });
    }
  }

  function registerFormBlurHandler(field) {
    setRegisterForm((prevState) => {
      return {
        ...prevState,
        [field]: { ...prevState[field], touched: true },
      };
    });
  }

  function registerFormSubmitHandler(event) {
    event.preventDefault();

    let fields = Object.keys(registerForm);
    let formTotalValidation = true;

    for (let i = 0; i < fields.length; i++) {
      if (registerForm[fields[i]].valid === false) {
        formTotalValidation = false;
      }
      setRegisterForm((prevState) => {
        return {
          ...prevState,
          [fields[i]]: { ...prevState[fields[i]], touched: true },
        };
      });
    }

    if (registerForm.passwordConfirm.passwordMatch === false) {
      formTotalValidation = false;
    }

    let formData = {
      email: registerForm.email.value,
      userName: registerForm.userName.value,
      passwordInput: registerForm.password.value,
    };

    if (formTotalValidation) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      };
      let registerUrl = process.env.REACT_APP_BASE_URL + "/User/register";
      fetch(registerUrl, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.errorCode) {
            toast.info(data.message, { theme: "colored" });
          } else {
            localStorage.setItem("accessToken", data.accessToken);
            userContext.refresh();
            navigate("/");
          }
        })
        .catch((error) => {
          if (error.message === "Failed to fetch") {
            toast.info("Can not connect server", { theme: "colored" });
          }
        });
    }
  }

  return (
    <div className="container mb-5">
      <form onSubmit={registerFormSubmitHandler}>
        <div className="row">
          <div className="col-sm-6 col-sm-offset-3 mx-auto p-5">
            <h2 className="mb-5 ">Register to Warrior Tale</h2>
            <div className="mb-3">
              <label htmlFor="inputEmail" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="inputEmail"
                value={registerForm.email.value}
                onChange={(event) =>
                  registerFormChangeHandler("email", event.target.value)
                }
                onBlur={(event) => registerFormBlurHandler("email")}
              />
              {!registerForm.email.valid && registerForm.email.touched ? (
                <div className="form-text form-validation-text">
                  <div>Need email</div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="inputUserName" className="form-label">
                User name
              </label>
              <input
                type="text"
                className="form-control"
                id="userName"
                onChange={(event) =>
                  registerFormChangeHandler("userName", event.target.value)
                }
                onBlur={(event) => registerFormBlurHandler("userName")}
              />
              {!registerForm.userName.valid && registerForm.userName.touched ? (
                <div className="form-text form-validation-text">
                  <div>Need user name</div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="inputPassword" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="inputPassword"
                onChange={(event) =>
                  registerFormChangeHandler("password", event.target.value)
                }
                onBlur={(event) => registerFormBlurHandler("password")}
              />
              {!registerForm.password.valid && registerForm.password.touched ? (
                <div className="form-text form-validation-text">
                  <div>Need password</div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="inputPassword" className="form-label">
                Password Confirm
              </label>
              <input
                type="password"
                className="form-control"
                id="inputPasswordConfirm"
                onChange={(event) =>
                  registerFormChangeHandler(
                    "passwordConfirm",
                    event.target.value
                  )
                }
                onBlur={(event) => registerFormBlurHandler("passwordConfirm")}
              />
              {!registerForm.passwordConfirm.valid &&
              registerForm.passwordConfirm.touched ? (
                <div className="form-text form-validation-text">
                  <div>Need password confirm</div>
                </div>
              ) : (
                ""
              )}
              {!registerForm.passwordConfirm.passwordMatch &&
              registerForm.passwordConfirm.touched ? (
                <div className="form-text form-validation-text">
                  <div>Passwords are not matching</div>
                </div>
              ) : (
                ""
              )}
            </div>

            <button type="submit" className="btn btn-light mt-3 mb-4">
              Register
            </button>

            <div>
              <Link to="/">Homepage</Link>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
