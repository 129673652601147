import { toast } from "react-toastify";

let baseUrl = process.env.REACT_APP_BASE_URL;
let accessToken = localStorage.getItem("accessToken");

export async function getWarbandPlansApi() {
  let url = baseUrl + "/tribe/warband-plan";

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  };

  return fetch(url, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        throw new Error("Not Authenticated");
      }
      return response.json();
    })
    .catch((error) => {
      if (error.message === "Failed to fetch") {
        toast.info("Can not connect server", { theme: "colored" });
      }
    });
}


export async function getTribeByNameApi(tribeName) {
  let url = baseUrl + "/tribe/name-search/" + tribeName;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  };

  return fetch(url, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        throw new Error("Not Authenticated");
      }
      return response.json();
    })
    .catch((error) => {
      if (error.message === "Failed to fetch") {
        toast.info("Can not connect server", { theme: "colored" });
      }
    });
}


export async function postApplyWarlordApi() {
  let url = baseUrl + "/tribe/apply-warlord";

  let requestBody = {

  }

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,     
    },
    body: JSON.stringify(requestBody)
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.status === 401) {
      throw new Error("Not Authenticated");
    }
    return response.json();
  });
}


export async function getWarlordApplicationApi() {
  let url = baseUrl + "/tribe/warlord-application";

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  };

  return fetch(url, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        throw new Error("Not Authenticated");
      }
      return response.json();
    })
    .catch((error) => {
      if (error.message === "Failed to fetch") {
        toast.info("Can not connect server", { theme: "colored" });
      }
    });
}













