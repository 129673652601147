import { HomeInfoCard } from "../General/HomeInfoCard";
import { Link } from "react-router-dom";
import "./Home.css";

export function Home() {
  let cardInfo = {
    title: "Warrior Life",
    content: `About text`,
    image: "imagepath",
  };

  let cardInfoList = [
    {
      title: "Warrior Life",
      content: `Warrior adventure, train your strong warrior and join warbands. Win tribe battles.`,
      image: "images/general/warrior-winter.png",
    },
    {
      title: "Tribes",
      content: `Become warlord or warrior of tribes. Lead your tribe to victory.`,
      image: "images/general/warlord.png",
    },
  ];

  return (
    <div>
      <div className="warrior-tale-container mt-3 mx-auto">
        <h1>Warrior Tale</h1>
        <p>
          Warrior Tale is an online browser game. You will play as a warrior at
          warrior tale and choose your warrior’s destiny. Warriors do daily
          activities and skill training. Strong warriors have a better chance in
          tribe wars. Warriors can claim to be warlords at scheduled times so
          they can create their warband. Better warbands mean better rewards.
        </p>
        <div>
          <Link to="/login">
            <button className="btn btn-dark">Login</button>
          </Link>

          <Link to="/register">
            <button className="btn btn-dark ms-3">Register</button>
          </Link>
        </div>
      </div>

      <div className="warrior-tale-container-wide mx-auto">
        <HomeInfoCard cardInfo={cardInfoList[0]} />
        <HomeInfoCard cardInfo={cardInfoList[1]} />
      </div>
    </div>
  );
}
