import { useState } from "react"

export function Pet(){
    let tempPetList = [
        {name: 'Dog', avatar: 'dog', description: ''},
        {name: 'Cat', avatar: 'cat', description: ''},
        {name: 'Bird', avatar: 'bird', description: ''}
    ];

    const [petList, setPetList] = useState(tempPetList);

    const petVisual = petList.map((pet) => {
        return (
          <div key={pet.name} >
            <h5>{pet.name}</h5>
            <img
              src={"images/pet/" + pet.avatar + ".png"}
              alt="lady profile"
              width="100%"
            />
            <button className="btn btn-dark mt-2"  >
              Adopt
            </button>
            <div className="mb-3 p-1" style={{fontSize: 15}} >
                {pet.description}
            </div>
            
          </div>
        );
      });

    return (
        <div>
            <h3>Pet</h3>
            <div className="game-grid" >
                {petVisual}
            </div>
        </div>
    )
}