export function EncyclopediaWarrior(){
    return (
        <div>
            <h4>
                Warrior
            </h4>
            <p>
                Every player starts with a warrior character in the warrior tale.
                You can do daily activities. Training your warrior's skill.
                Working to earn money. Buying food with money. 
                Increase training quality with food. Join tribe battles.

            </p>
        </div>
    )   
}