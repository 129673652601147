import warlordImage from "../../assets/images/menu/warlord.png";
import {
  getWarbandAdvertisementApi,
  postApplyAdvertisementApi,
  startWorkApi,
} from "../../services/warriorService";
import "./Tribe.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import CharacterContext from "../../store/CharacterContext";
import { Link } from "react-router-dom";

export const Tribe = () => {
  const navigate = useNavigate();
  const characterContext = useContext(CharacterContext);

  const [warbandAdvertisements, setwarbandAdvertisements] = useState([]);
  const [characterTribe, setCharacterTribe] = useState(null);


  useEffect(() => {
    getWarbandAdvertisementApi()
      .then((data) => {
        setwarbandAdvertisements(data);
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
          toast.info("Can not connect server", { theme: "colored" });
        }
      });
  }, []);

  useEffect(() => {
    if (characterContext.work === "Warrior") {
      setCharacterTribe(characterContext.workInformation);
    }
  }, [characterContext]);

  function startWork(workName) {
    startWorkApi("Farming")
      .then((data) => {
        if (data.errorCode) {
          toast.info(data.message, { theme: "colored" });
        } else {
          toast.success("Working", { theme: "colored" });
          characterContext.setNewValue(data);
          navigate("/life");
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
          toast.info("Can not connect server", { theme: "colored" });
        }
      });
  }

  function processAdvertisementData(warbandAdvertisement) {
    let warbandOrderName = "Primary";
    let warriorLevelName = "Recruit";
    let warriorWage = 10;

    if (warbandAdvertisement.warbandOrder === 2) {
      warbandOrderName = "Secondary";
    }

    switch (warbandAdvertisement.warriorLevel) {
      case 1:
        warriorLevelName = "Recruit";
        warriorWage = 25;
        break;
      case 2:
        warriorLevelName = "Soldier";
        warriorWage = 40;
        break;
      case 3:
        warriorLevelName = "Champion";
        warriorWage = 75;
        break;
      case 4:
        warriorLevelName = "Elite Champion";
        warriorWage = 250;
        break;
      case 5:
        warriorLevelName = "Legend";
        warriorWage = 500;
        break;
      default:
        warriorLevelName = "Recruit";
        break;
    }

    let responseData = {
      warbandOrderName: warbandOrderName,
      warriorLevelName: warriorLevelName,
      warriorWage: warriorWage,
    };

    return responseData;
  }

  let warbandAdvertisementsVisual = warbandAdvertisements.map(
    (warbandAdvertisement) => {
      let processedAdvertisementData =
        processAdvertisementData(warbandAdvertisement);
      return (
        <div
          key={warbandAdvertisement.id}
          className="d-flex align-items-center mt-2"
        >
          <div>
            {processedAdvertisementData.warriorLevelName} (
            {warbandAdvertisement.warriorType})
            <i
              className="bi bi-octagon-fill ms-2 me-1"
              style={{ color: `#${warbandAdvertisement.tribeColor}` }}
            ></i>
            {warbandAdvertisement.tribeName}
            Tribe/{processedAdvertisementData.warbandOrderName} Warband - Wage:{" "}
            {processedAdvertisementData.warriorWage} Gold
          </div>

          <button
            className="btn btn-dark ms-2"
            onClick={(event) => applyAdvertisement(warbandAdvertisement.id)}
          >
            Join
          </button>
        </div>
      );
    }
  );

  function applyAdvertisement(advertisementId) {
    postApplyAdvertisementApi(advertisementId)
      .then((data) => {
        if (data.errorCode) {
          toast.info(data.message, { theme: "colored" });
        } else {
          toast.success("Warrior", { theme: "colored" });
          characterContext.setNewValue(data);
          navigate("/life");
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
          toast.info("Can not connect server", { theme: "colored" });
        }
      });
  }

  let characterTribeInformationVisual = characterTribe ? (
    <div className="mt-5">
      <div className="fs-4 fw-bold d-flex align-items-center">
        <div>
          You are member of {characterTribe.tribeName} Tribe
          <i
            className="bi bi-octagon-fill ms-2"
            style={{ color: `#${characterTribe.tribeColor}` }}
          ></i>
        </div>

        <Link to={"/tribe/" + characterTribe.tribeName.toLowerCase()}>
          <button className="btn btn-dark ms-4">Go to Tribe</button>
        </Link>
      </div>
    </div>
  ) : (
    ""
  );

  let characterWarlordInformationVisual = characterContext.warlordCharacter ? (
    <div className="mt-5">
      <div className="fs-4 fw-bold d-flex align-items-center">
        <div>
          You are warlord of {characterContext.tribeInformation.tribeName} Tribe
          <i
            className="bi bi-octagon-fill ms-2"
            style={{
              color: `#${characterContext.tribeInformation.tribeColor}`,
            }}
          ></i>
        </div>

        <Link
          to={
            "/tribe/" +
            characterContext.tribeInformation.tribeName.toLowerCase()
          }
        >
          <button className="btn btn-dark ms-4">Go to Tribe</button>
        </Link>
      </div>
    </div>
  ) : (
    ""
  );

  return (
    <div>
      <h3>Tribe</h3>
      <div className="d-flex align-items-center">
        <img src={warlordImage} alt="warlord" className="warlord-image" />
        <div className="ms-2">
          Warlords hiring strong warriors for their tribe, You can search
          suitable warband for you. Warlords will pay you wage periodical.
        </div>
      </div>

      {characterContext.warlordCharacter ? characterWarlordInformationVisual : characterTribeInformationVisual }

      <div className="mt-5 p-2">
        <h4>Jobs</h4>
        <div>
          <div className="d-flex align-items-center">
            <div>Farming - Wage: 20 Gold</div>

            <button
              className="btn btn-dark ms-2"
              onClick={() => startWork("Farming")}
            >
              Work
            </button>
          </div>
        </div>

        <hr />

        <div className="mt-4">
          <h5>Warband Positions</h5>
          {warbandAdvertisementsVisual}
        </div>
      </div>
    </div>
  );
};
