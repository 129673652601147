export function EncyclopediaBattle(){
    return (
        <div>
            <h4>
                Battle
            </h4>
            <p>
            Warbands of tribes’ battles start on the 6th day of month and end on the 25th day. Winner warriors earn double bonus for their warband payment. Warriors gain or lose prestige according to the result of the battle.
            </p>
        </div>
    )   
}