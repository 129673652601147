import "./Header.css";
import { Link } from "react-router-dom";
import helmetIcon from "../../assets/images/game/helmet-icon.png";
import { useContext } from "react";
import CharacterContext from "../../store/CharacterContext";
import UserContext from "../../store/UserContext";

import "./Some.scss";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

export function Header() {
  const userContext = useContext(UserContext);
  const characterContext = useContext(CharacterContext);

  const logout = () => {
    userContext.logout();
    characterContext.refreshCharacterData(true);
  };

  return (
    <Navbar expand="lg" className="bg-body-tertiary game-header" variant="dark">
      <Container fluid>
        <Navbar.Brand>
          <Link to="/" className="non-decorated-link">
            Warrior Tale
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as="span" >
              <Link to="/info" className="non-decorated-link">
                Game Information
              </Link>
            </Nav.Link>


            <Nav.Link as="span">
              <Link to="/warriors" className="non-decorated-link">
                Warriors
              </Link>
            </Nav.Link>
            <Nav.Link as="span">
              <Link to="/tribes" className="non-decorated-link">
                Tribes
              </Link>
            </Nav.Link>
            <Nav.Link as="span">
              <Link to="/warlords" className="non-decorated-link">
                Warlords
              </Link>
            </Nav.Link>
            <Nav.Link as="span">
              <Link to="/game-encylopedia" className="non-decorated-link">
                Game Encyclopedia
              </Link>
            </Nav.Link>
          </Nav>

          <div>
            {userContext.isAuthenticated ? (
              <span className="text-capitalize">
                Welcome {userContext.userName}
              </span>
            ) : (
              ""
            )}
            {userContext.isAuthenticated ? (
              <button className="btn btn-dark ms-2" onClick={logout}>
                Logout
              </button>
            ) : (
              <Link to="/login">
                <button className="btn btn-dark ms-2">Login</button>
              </Link>
            )}
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
