
export function getWarriorTitleTextByLevel(warriorLevel) {
  let warriorTitle = "Recruit";

  switch (warriorLevel) {
    case 1:
      warriorTitle = "Recruit";
      break;
    case 2:
      warriorTitle = "Soldier";
      break;
    case 3:
      warriorTitle = "Champion";
      break;
    case 4:
      warriorTitle = "Elite Champion";
      break;
    case 5:
      warriorTitle = "Legend";
      break;
    default:
      break;
  }
  return warriorTitle;
}


export function getWarbandOrderName(orderNumber){
    let warbandOrderName = "Primary";

    if(orderNumber === 2){
        warbandOrderName = "Secondary";
    }
    
    return warbandOrderName;
}


