import { useEffect, useState } from "react";
import greenGem from "../../assets/images/menu/green-gem.png";
import orangeGem from "../../assets/images/menu/orange-gem.png";
import redGem from "../../assets/images/menu/red-gem.png";

import "./EventMenu.css";
import { getEventsApi } from "../../services/warriorService";

export function EventsMenu() {
  const tempEventInfos = [
    { description: "Warlord paid you 200 gold", level: "green" },
    { description: "Happy birthday", level: "green" },
    { description: "Raiders preparing", level: "red" },
    { description: "Warlord paid you 300 gold", level: "green" },
    { description: "Appointed council", level: "orange" },
    { description: "Warlord paid you 300 gold", level: "green" },
  ];
  const [characterEvents, setcharacterEvents] = useState([]);
  const [pages, setPages] = useState([1]);
  const [totalPage, setTotalPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1);
  

  let gemCondition = (itemLevel) => {
    itemLevel = itemLevel.toLowerCase();
    if (itemLevel === "green") {
      return greenGem;
    } else if (itemLevel === "orange") {
      return orangeGem;
    } else if (itemLevel === "red") {
      return redGem;
    }
  };

  const eventsVisual = tempEventInfos.map((item) => {
    return (
      <div className="d-flex align-items-center mb-2">
        <img
          src={gemCondition(item.level)}
          alt="green gem"
          className="game-gem-image-icon"
        />
        <div className="ms-2">{item.description}</div>
      </div>
    );
  });

  useEffect(() => {
    getEventsApi(1).then((data) => {
      setcharacterEvents(data.dataList);
    
      createPageArray(data.totalPage);
    });
  }, []);

  function changePage(pageNumber){
    if(pageNumber > totalPage){
      pageNumber = totalPage;
    }
    if(pageNumber < 1){
      pageNumber = 1;
    }
    getEventPage(pageNumber);
  }

  function getEventPage(targetPage) {
    getEventsApi(targetPage).then((data) => {
      setcharacterEvents(data.dataList);

      setCurrentPage(targetPage);
    });
  }

  

  function formatDate(inputDate) {
    let date = new Date(inputDate);
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = date.getFullYear();

    let minutes = date.getMinutes();
    let hour = date.getHours();

    let preparedDate = dd + "/" + mm + "/" + yyyy + ` - ${hour}:${minutes} `;
    return preparedDate;
  }

  function processEventText(eventItem) {
    let eventText = "Invalid event";
    if (eventItem.eventName === "Warlord") {
      eventText = `You become warlord of the ${eventItem.eventParameter1} Tribe`;
    }else if (eventItem.eventName === "WarlordSessionEnd") {
      eventText = `Your warlord session ended at the ${eventItem.eventParameter1} Tribe`;
    }else if (eventItem.eventName === "WarbandSessionEnd") {
      eventText = `Your warband session ended at the ${eventItem.eventParameter1} Tribe`;
    }else if (eventItem.eventName === "WarbandPayment") {
      eventText = `${eventItem.eventParameter1} Tribe paid you ${eventItem.eventParameter3} gold`;
    }else if (eventItem.eventName === "WarlordPayment") {
      eventText = `Collected taxes at ${eventItem.eventParameter1} Tribe, ${eventItem.eventParameter3} gold`;
    }else if (eventItem.eventName === "Married") {
      eventText = `You are married`;
    }else if (eventItem.eventName === "CharacterStart") {
      eventText = `You started`;
    }else if (eventItem.eventName === "TrainSkill") {
      eventText = `You increased your skill by ${eventItem.eventParameter2} at training (${eventItem.eventParameter1})`;
    }else if (eventItem.eventName === "WifeTrainBonus") {
      eventText = `${eventItem.eventParameter2} increased your training gain`;
    }else if (eventItem.eventName === "WifeMoneyLost") {
      eventText = `${eventItem.eventParameter1} spent some money (${eventItem.eventParameter2} gold)`;
    }else if (eventItem.eventName === "WifeMoneySupport") {
      eventText = `${eventItem.eventParameter1} gives economic support (${eventItem.eventParameter2} gold)`;
    }else if (eventItem.eventName === "WarlordBattleVictory") {
      eventText = `Your tribe ${eventItem.eventParameter1} defeat ${eventItem.eventParameter2} tribe`;
    }else if (eventItem.eventName === "WarlordBattleDefeat") {
      eventText = `Your tribe ${eventItem.eventParameter1} defeated by ${eventItem.eventParameter2} tribe`;
    }else if (eventItem.eventName === "WarriorBattleVictory") {
      eventText = `Your warband at ${eventItem.eventParameter1} tribe defeat ${eventItem.eventParameter2} tribe, you gain ${eventItem.eventParameter3} bonus gold`;
    }else if (eventItem.eventName === "WarriorBattleDefeat") {
      eventText = `Your warband at ${eventItem.eventParameter1} tribe defeated by ${eventItem.eventParameter2} tribe`;
    }

    return eventText;
  }

  const eventTable = characterEvents.map((eventItem) => {
    return (
      <tr key={eventItem.id}>
        <td>
          <img
            src={gemCondition(eventItem.color)}
            alt="green gem"
            className="game-gem-image-icon"
          />
          <span className="ms-2">{processEventText(eventItem)}</span>
        </td>
        <td className="text-end">{formatDate(eventItem.eventDate)}</td>
        <td className="text-capitalize"></td>
      </tr>
    );
  });

  function createPageArray(pageNumber) {
    setTotalPage(pageNumber);
    let newPageArray = [];
    for (let i = 1; i <= pageNumber; i++) {
      newPageArray.push(i);
    }
    setPages(newPageArray);
  }

  let pagesInRange = pages.filter((item) => {
    let pageRange = 3;
    if (item >= currentPage - pageRange && item <= currentPage + pageRange) {
      return true;
    } else {
      return false;
    }
  });

  let pageNumberVisual = pagesInRange.map((pageNumber) => {
    return (
      <li className="page-item" key={pageNumber}>
        <button
          className={`page-link ${currentPage === pageNumber ? "active" : ""}`}
          onClick={() => getEventPage(pageNumber)}
        >
          {pageNumber}
        </button>
      </li>
    );
  });

  return (
    <div>
      <h4>Events</h4>

      <div>
        {/* {eventsVisual} */}
        <table className="table">
          <tbody>{eventTable}</tbody>
        </table>

        <div>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <button className="page-link" onClick={() => changePage(currentPage-1)} >
                  Previous
                </button>
              </li>

              {pagesInRange[0] > 1 ? (<li className="page-item">
                <button
                  className="page-link"
                  onClick={() => getEventPage(1)}
                >
                  ...
                </button>
              </li>) : ('')}

              

              {pageNumberVisual}

              {pagesInRange[pagesInRange.length-1] < totalPage ? (<li className="page-item">
                <button
                  className="page-link"
                  onClick={() => getEventPage(totalPage)}
                >
                  ...
                </button>
              </li>) : ('')}

              <li className="page-item">
                <button className="page-link" onClick={() => changePage(currentPage+1)} >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
