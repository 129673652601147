import { useContext, useEffect, useState } from "react";
import { Game } from "./Game";
import { Home } from "./Home";
import { Welcome } from "./Welcome";
import UserContext from "../../store/UserContext";

export function Main() {
  const [authenticated, setAuthenticated] = useState(false);
  const userContext = useContext(UserContext);

  useEffect(() => {
    let token = localStorage.getItem('accessToken')
    if(token){
      setAuthenticated(true);
    }else{
      setAuthenticated(false);
    }
    
  }, []);

  return (
    <main>
      {userContext.isAuthenticated ? <Game /> : <Welcome />}
      
      
    </main>
  );
}
