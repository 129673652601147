import warrior1 from '../../assets/images/character/character1.png'
import warrior2 from '../../assets/images/warrior1.jpg'
import characterImage from '../../assets/images/character/character1.png'
import styles from './CharacterInfo.css'
import { useContext } from 'react';
import CharacterContext from '../../store/CharacterContext';

export function CharacterInfo(){
    let characterName = "Jurgen";
    let characterLevel = 5;
    let power = 78;

    const characterContext = useContext(CharacterContext);

    return(
        <div className="character-menu-container" >
            <img src={ '/images/character/' + characterContext.avatar + '.png' } alt='character' className='w-100'  />

            <div className='text-capitalize' >
                Name: {characterContext.characterName} <br/>
                Overall: {characterContext.overall} <br/>
                Money: {characterContext.money} <br/>
                
            </div>

        </div>
    );
}