import { GameMediumContainer } from "../General/GameMediumContainer";
import { Link } from 'react-router-dom'

export function GameInformation(props) {
  return (
    <div>
      <GameMediumContainer innerContainer={props.innerContainer}>
        <h2>Game Information</h2>
        <div>
          <p>
            Warrior Tale is an online browser game. Game is free to play. You
            will play a warrior in Warrior Tale. You will rise your warrior, win
            battles.
            <br/>

            Game Discord Channel: 
            <Link target="_blank" to="https://discord.gg/e5nwCRcwyK">https://discord.gg/e5nwCRcwyK</Link>
          </p>
        </div>
      </GameMediumContainer>
    </div>
  );
}
