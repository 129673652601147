import { useEffect, useState } from "react";
import { getAllCharacterApi, getAllWarlordsApi } from "../../services/warriorService";
import { GameMediumContainer } from "../General/GameMediumContainer";

export function Warlords(props) {
  const [warlords, setWarlords] = useState([]);

  useEffect(() => {
    getAllWarlordsApi().then((data) => {
      if(data){
        setWarlords(data);
      }     
    });
  }, []);

  let rankingCounter = 0;
  let warlordsTableRows = warlords.map((warrior) => {
    rankingCounter++;
    return (
      <tr key={warrior.id}>
        <th scope="row">{rankingCounter}</th>
        <td className="text-capitalize">
          <div className="d-flex align-items-center">
            <div>
              <img
                width="60px"
                className="game-menu-image warrior-rank-image"
                src={`images/character/${warrior.avatar}.png`}
                alt="warrior portrait"
              />
            </div>
            <div className="ms-3">{warrior.characterName}</div>
          </div>
        </td>
        <td className="general-table-td">{warrior.warlordPrestige}</td>
        <td className="general-table-td">{warrior.tribeInformation.tribeName}</td>
      </tr>
    );
  });

  return (
    <div>
      <GameMediumContainer innerContainer={props.innerContainer} >
        <h2>Warlords</h2>
        <div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Warlord</th>
                <th scope="col">Prestige</th>
                <th scope="col">Tribe</th>
              </tr>
            </thead>
            <tbody>{warlordsTableRows}</tbody>
          </table>
        </div>
      </GameMediumContainer>
    </div>
  );
}
