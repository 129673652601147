import { useContext, useEffect } from "react";
import CharacterContext from "../../store/CharacterContext";
import "./Inventory.css";
import { actionItemApi } from "../../services/warriorService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export function Inventory() {
  const characterContext = useContext(CharacterContext);
  const navigate = useNavigate();

  useEffect(() => {}, []);

  function actionItem(itemId) {
    actionItemApi(itemId).then((data) => {
      if (data.errorCode) {
        toast.info(data.message, { theme: "colored" });
      } else {
        toast.success("Satiated", { theme: "colored" });
        characterContext.setNewValue(data);
        navigate('/life');
      }
    }).catch((error) => {
      toast.info(error.message, { theme: "colored" });
    })

  }

  let inventoryTableRows =
    characterContext.characterInventory?.totalInventoryWithInfo.map((item) => {
      return (
        <tr key={item.gameItemId} className={(item.quantity === 0 ? 'hide-item' : '')} >
          <td>
            <img
              src={"images/item/" + item.gameItem.avatar + ".png"}
              className="inventory-image"
              alt="gameItemName"
              width="70px"
            />
          </td>
          <td className="text-capitalize">{item.gameItem.gameItemName}</td>
          <td>{item.quantity}</td>
          <td>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-dark ms-2"
                onClick={() => actionItem(item.gameItemId)}
              >
                Use Item
              </button>
            </div>
          </td>
        </tr>
      );
    });

  return (
    <div>
      <h3>Inventory</h3>

      <div>
        <table className="table table-bordered border-secondary">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Item</th>
              <th scope="col">Quantity</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {inventoryTableRows}
          </tbody>
        </table>
      </div>
    </div>
  );
}
