import foodImage from "../../assets/images/menu/food.png";
import workImage from "../../assets/images/menu/town.png";
import warriorWinter from "../../assets/images/menu/warrior-winter.png";
import marriageRings from "../../assets/images/menu/marriage-rings.png";
import { Training } from "./Training";
import "./Life.css";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import CharacterContext from "../../store/CharacterContext";
import {
  getWarbandOrderName,
  getWarriorTitleTextByLevel,
} from "../../services/commonService";

export function Life() {
  const characterContext = useContext(CharacterContext);
  const navigate = useNavigate();

  function tokenControlForProtectedPage() {
    let token = localStorage.getItem("accessToken");
    if (!token) {
      return navigate("/login");
    }
    return false;
  }
  useEffect(() => {
    tokenControlForProtectedPage();
  });

  function prepareHungerText(inputValue) {
    if (inputValue === "None") {
      return "None";
    } else {
      if (inputValue) {
        let foodName = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
        return `Satiated (${foodName})`;
      } else {
        return `Satiated`;
      }
    }
  }

  function prepareWorkText(inputValue, workInformation) {
    if (inputValue === "None") {
      return "None";
    } else if (inputValue === "Farming") {
      return "Farming";
    } else if (inputValue === "Warrior") {
      let warriorTitle = getWarriorTitleTextByLevel(
        workInformation.warriorLevel
      );
      let warbandOrderName = getWarbandOrderName(workInformation.warbandOrder);

      let finalText = `${warbandOrderName} Warband (${warriorTitle})`;
      return finalText;
    } else {
      return "Invalid";
    }
  }

  return (
    <div>
      <h3>Life</h3>
      <div>
        <div className="d-flex align-items-center">
          <img
            src={
              characterContext.wifeInformation
                ? `images/character-npc/${characterContext.wifeInformation.avatar}.png`
                : marriageRings
            }
            alt="bread"
            className="game-image-icon"
          />
          <div className="ms-2">
            Wife:{" "}
            {characterContext.wifeInformation
              ? characterContext.wifeInformation.wifeName
              : "None"}
            <Link to="/wife">
              <button className="btn btn-dark ms-3">Marry</button>
            </Link>
          </div>
        </div>

        {/* <div className="d-flex align-items-center mt-2">
          <img src={foodImage} alt="bread" className="game-image-icon" />
          <div className="ms-2">
            Pet: None
            <Link to="/pet">
              <button className="btn btn-dark ms-3">Adopt</button>
            </Link>
          </div>
        </div> */}
      </div>

      <div className="mt-5">
        <h4>Daily</h4>
        <div className="d-flex align-items-center">
          <img src={foodImage} alt="bread" className="game-image-icon" />
          <div
            className={
              "ms-2 " +
              (characterContext.hunger === "None" ? "warning-text" : "")
            }
          >
            Hunger: {prepareHungerText(characterContext.hunger)}
          </div>
          <Link to="/inventory">
            <button className="btn btn-dark ms-2">Look Inventory</button>
          </Link>
        </div>
        <div className="d-flex align-items-center mt-2">
          <img src={workImage} alt="bread" className="game-image-icon" />
          <div
            className={`ms-2 ${
              characterContext.work === "None" ? "warning-text" : ""
            }`}
          >
            Work:{" "}
            {prepareWorkText(
              characterContext.work,
              characterContext.workInformation
            )}
          </div>
          <Link to="/tribe">
            <button className="btn btn-dark ms-2">Search Job</button>
          </Link>
        </div>
      </div>
      <div className="mt-5">
        <h4>Training</h4>

        <div className="d-flex align-items-center">
          <img src={warriorWinter} alt="bread" className="game-image-icon" />
          <div
            className={`ms-2 ${
              characterContext.train === "None" ? "warning-text" : ""
            }`}
          >
            Active Training: {characterContext.train}
          </div>
        </div>

        <Training />
      </div>
    </div>
  );
}
