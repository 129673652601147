import { useContext, useEffect, useState } from "react";
import { getTribesApi, getTribesMediumApi } from "../../services/warriorService";
import { Link } from "react-router-dom";
import CharacterContext from "../../store/CharacterContext";
import { toast } from "react-toastify";
import { getWarbandPlansApi, getWarlordApplicationApi, postApplyWarlordApi } from "../../services/tribeService";

export function Warlord() {
  const [tribeList, setTribeList] = useState([]);
  const characterContext = useContext(CharacterContext);
  const [characterTribe, setCharacterTribe] = useState(null);
  const [warlordApplication, setWarlordApplication] = useState(null);

  useEffect(() => {
    getTribesMediumApi().then((data) => {
      setTribeList(data);
    })
    .catch((error) => {
      if (error.message === "Failed to fetch") {
        toast.info("Can not connect server", { theme: "colored" });
      }
    });

    getWarlordApplicationApi().then((data) => {
      if(data){
        setWarlordApplication(data);
      }

    })

  }, []);

  useEffect(() => {
    function controlWarlord() {
      let warlordTribe = tribeList.find((tribe) => {
        return characterContext.id === tribe.warlordId;
      });
      if (warlordTribe) {
        setCharacterTribe(warlordTribe);
      }
    }

    controlWarlord();
  }, [tribeList, characterContext]);


  

  let tribeTableRows = tribeList.map((tribe) => {
    return (
      <tr key={tribe.id} style={{ fontSize: 20 }}>
        {/* <td style={{backgroundColor: `#${tribe.color}` }} ></td> */}
        <td>
          <span>
            <i
              className="bi bi-octagon-fill"
              style={{ color: `#${tribe.color}` }}
            ></i>
          </span>
          <span className="ms-3">{tribe.tribeName}</span>
        </td>
        <td>{tribe.prestige}</td>
        <td className="text-capitalize" >{tribe.warlord ? tribe.warlord.characterName : "Npc"}</td>
      </tr>
    );
  });

  function applyWarlord(){
    postApplyWarlordApi().then((data) => {
      setWarlordApplication(data);
    })
  }

  return (
    <div>
      <h3>Warlords</h3>
      <div>
        {characterTribe != null ? (
          <div className="fs-4 fw-bold mt-3 mb-4 d-flex">
            <div>
              You are Warlord of {characterTribe.tribeName}
              <i
                className="bi bi-octagon-fill ms-2"
                style={{ color: `#${characterTribe.color}` }}
              ></i>
            </div>
            <Link to="/tribe-manage">
              <button className="btn btn-dark ms-4">Manage</button>
            </Link>
          </div>
        ) : (
          ""
        )}

        <table className="table">
          <thead>
            <tr>
              {/* <th scope="col"></th> */}
              <th scope="col">Tribe</th>
              <th scope="col">Prestige</th>
              <th scope="col">Warlord</th>
            </tr>
          </thead>
          <tbody>{tribeTableRows}</tbody>
        </table>

        <div>Your Claim: {warlordApplication != null ? 'Have claim to be warlord' : 'Haven\'t claim'}</div>

          {warlordApplication == null ? (<div className="mt-3">
          <button className="btn btn-dark" onClick={() => applyWarlord()} >Claim to be Warlord</button>
        </div>) : ('')}

        
      </div>
    </div>
  );
}
