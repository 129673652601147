import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { hideLoadingSpinner, serverRequest, showLoadingSpinner } from "../../services/warriorService";
import UserContext from "../../store/UserContext";
import { toast } from "react-toastify";
import { getAccessToken } from "../../services/warriorService";

export const Login = () => {
  const [loginForm, setLoginForm] = useState({
    email: { valid: false, value: '', touched: false},
    password: { valid: false, value: '', touched: false}
  });

  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const loginFormSubmitHandler = (event) => {
    event.preventDefault();
    let fields = Object.keys(loginForm);
    let formTotalValidation = true;

    for(let i = 0; i < fields.length; i++){
        if(loginForm[fields[i]].valid == false ){
            formTotalValidation = false;
        }
        setLoginForm((prevState) => {
            return {
                ...prevState,
                [fields[i]]: {...prevState[fields[i]], touched: true }
            }
        })
    }


    let formData = {
        email: loginForm.email.value,
        passwordInput: loginForm.password.value
    }

    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData)
    }

    let loginUrl = process.env.REACT_APP_BASE_URL + '/User/login'
    showLoadingSpinner(); 
    fetch(loginUrl, requestOptions)
        .then(async (response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            let responseJson = await response.json()
            return responseJson;
          } else {
            let responseText = await response.text();
            throw new Error(responseText);
          }        
        })
        .then((data) => {
            if(data.errorCode){
                console.log(data.message)
            }else{
                localStorage.setItem('accessToken', data.accessToken)
                userContext.refresh();
                getAccessToken();
                navigate('/')
            }          
        })
        .catch((error) => {       
          console.log(error)
          if(error.message === "Failed to fetch"){
            toast.info("Can not connect server", {theme: "colored"})
          }else{
            toast.info(error.message, {theme: "colored"})
          }
        })
        .finally(() => {
          hideLoadingSpinner();
        })
  };




  const loginFormChangeHandler = (field, input) => {
    setLoginForm((prevState) => {
        let validity = input.length > 2 ? true : false;
        return {
            ...prevState,
            [field]: { ...prevState[field], value: input, valid: validity}
        }
    })
  };

  const loginFormBlurHandler = (field) => {
    setLoginForm((prevState) => {
        return {
            ...prevState,
            [field]: {...prevState[field], touched: true }
        }
    })
  };

  return (
    <div className="container mb-5">
      <form onSubmit={loginFormSubmitHandler}>
        <div className="row">
          <div className="col-sm-6 col-sm-offset-3 mx-auto p-5">
            <h2 className="mb-5 ">Login</h2>
            <div className="mb-3">
              <label htmlFor="inputEmail" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="inputEmail"
                value={loginForm.email.value}
                onChange={(event) =>
                  loginFormChangeHandler("email", event.target.value)
                }
                onBlur={(event) => loginFormBlurHandler("email")}
              />
              {!loginForm.email.valid && loginForm.email.touched ? (
                <div className="form-text form-validation-text">
                  <div>Need email</div>
                </div>
              ) : (
                ""
              )}
            </div>


            <div className="mb-3">
              <label htmlFor="inputPassword" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="inputPassword"
                onChange={(event) =>
                  loginFormChangeHandler("password", event.target.value)
                }
                onBlur={(event) => loginFormBlurHandler("password")}
              />
              {!loginForm.password.valid && loginForm.password.touched ? (
                <div className="form-text form-validation-text">
                  <div>Need password</div>
                </div>
              ) : (
                ""
              )}
            </div>


            <button type="submit" className="btn btn-light mt-3 mb-4">
              Login
            </button>

            <div>
              <Link to="/">Homepage</Link>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
