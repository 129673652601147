export function GameMediumContainer(props) {
  return (
    <div
      className={`general-game-container mx-auto p-3 ${
        props.innerContainer ? "inner-container " : "mt-3 "
      } `}
    >
      {props.children}
    </div>
  );
}
