import { useContext, useEffect, useState } from "react";
import { getLadyApi, postMarryApi } from "../../services/warriorService";
import { toast } from "react-toastify";
import CharacterContext from "../../store/CharacterContext";

export const Wife = () => {
  const [wifeList, setWifeList] = useState([])
  const characterContext = useContext(CharacterContext);


  useEffect(() => {
    getLadyApi().then((data) => {
      setWifeList(data);
    })
  },[])

  function marry(wifeId){
    // if(characterContext.wifeInformation != null){
    //   toast.info("Already married", { theme: "colored" });
    //   return;
    // }

    postMarryApi(wifeId).then((data) => {
      if (data.errorCode) {
        toast.info(data.message, { theme: "colored" });
      } else {
        characterContext.setNewValue(data);
        toast.success("Married", { theme: "colored" });
      }
    })
  }

  const wifeVisual = wifeList.map((wife) => {
    return (
      <div key={wife.name} >
        <h5>{wife.name}</h5>
        <img
          src={"images/character-npc/" + wife.avatar + ".png"}
          alt="lady profile"
          width="100%"
        />
        <button className="btn btn-dark mt-2" onClick={() => marry(wife.id)} >
          Marry
        </button>
        <div className="mb-3 p-1" style={{fontSize: 15}} >
            {wife.description}
        </div>
        
      </div>
    );
  });

  return (
    <div>
      <h3>Wife</h3>

      <div className="game-grid">{wifeVisual}</div>
    </div>
  );
};
