import { useEffect, useState } from "react";
import { postWarbandPlanApi } from "../../services/warriorService";
import "./TribeManage.css";
import { toast } from "react-toastify";
import { getWarbandPlansApi } from "../../services/tribeService";

export function TribeManage() {
  const [primaryWarbandPlan, setPrimaryWarbandPlan] = useState([
    { position: 1, warriorType: "Balanced", warriorLevel: 5 },
    { position: 2, warriorType: "Balanced", warriorLevel: 5 },
    { position: 3, warriorType: "Balanced", warriorLevel: 5 },
    { position: 4, warriorType: "Balanced", warriorLevel: 5 },
    { position: 5, warriorType: "Balanced", warriorLevel: 5 },
    { position: 6, warriorType: "Defence", warriorLevel: 4 },
    { position: 7, warriorType: "Defence", warriorLevel: 4 },
    { position: 8, warriorType: "Defence", warriorLevel: 4 },
    { position: 9, warriorType: "Attack", warriorLevel: 4 },
    { position: 10, warriorType: "Attack", warriorLevel: 4 },
  ]);
  const [primaryWarbandPlanReady, setPrimaryWarbandPlanReady] = useState(false);

  const [secondaryWarbandPlan, setSecondaryWarbandPlan] = useState([
    { position: 1, warriorType: "Balanced", warriorLevel: 4 },
    { position: 2, warriorType: "Balanced", warriorLevel: 4 },
    { position: 3, warriorType: "Balanced", warriorLevel: 4 },
    { position: 4, warriorType: "Balanced", warriorLevel: 4 },
    { position: 5, warriorType: "Balanced", warriorLevel: 4 },
    { position: 6, warriorType: "Defence", warriorLevel: 3 },
    { position: 7, warriorType: "Defence", warriorLevel: 3 },
    { position: 8, warriorType: "Defence", warriorLevel: 3 },
    { position: 9, warriorType: "Attack", warriorLevel: 3 },
    { position: 10, warriorType: "Attack", warriorLevel: 3 },
  ]);
  const [secondaryWarbandPlanReady, setSecondaryWarbandPlanReady] = useState(false);

  useEffect(() => {
    getWarbandPlansApi().then((data) => {
      let primaryPlan = data.find((item) => {
        // return item.warbandOrder === 1
        if(item.warbandOrder === 1){
          setPrimaryWarbandPlanReady(true);
          return true;
        }
        return false; 
      })
      let secondaryPlan = data.find((item) => {
        if(item.warbandOrder === 2){
          setSecondaryWarbandPlanReady(true);
          return true;
        }
        return false;       
      })
      let thirdPlan = data.find((item) => {
        return item.warbandOrder === 3
      })

      if(primaryPlan){
        setPrimaryWarbandPlan(primaryPlan.warbandPlanParts);
      }
      if(secondaryPlan){
        setSecondaryWarbandPlan(secondaryPlan.warbandPlanParts);
      }
      
    })

  },[])


  function changeWarriorLevel(position, newValue) {
    let newArray = primaryWarbandPlan.slice();
    newArray[position - 1].warriorLevel = newValue;

    setPrimaryWarbandPlan(newArray);
  }

  function changeWarriorLevelSecondaryWarband(position, newValue) {
    let newArray = secondaryWarbandPlan.slice();
    newArray[position - 1].warriorLevel = newValue;

    setSecondaryWarbandPlan(newArray);
  }

  function saveWarbandPlan(targetWarbandOrder) {
    let targetPlan = primaryWarbandPlan;
    if (targetWarbandOrder === 2) {
      targetPlan = secondaryWarbandPlan;
    }

    postWarbandPlanApi(targetPlan, targetWarbandOrder)
      .then((data) => {
        if (data.errorCode) {
          toast.info(data.message, { theme: "colored" });
        } else {
          toast.success("Warband plan saved", { theme: "colored" });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
          toast.info("Can not connect server", { theme: "colored" });
        }
      });
  }

  let primaryWarbandPositionCounter = 0;
  let primaryWarbandTableRows = primaryWarbandPlan.map((warbandPart) => {
    primaryWarbandPositionCounter++;
    let warbandPartPosition = primaryWarbandPositionCounter;
    return (
      <tr key={warbandPart.position}>
        <th>{warbandPartPosition}</th>
        <td>{warbandPart.warriorType}</td>
        <td>
          <select
            className="form-select"
            value={warbandPart.warriorLevel}
            disabled={primaryWarbandPlanReady}
            onChange={(event) =>
              changeWarriorLevel(warbandPartPosition, event.target.value)
            }
          >
            <option value="4">Elite Champion</option>
            <option value="5">Legend</option>
            <option value="3">Champion</option>
            <option value="2">Soldier</option>
            <option value="1">Recruit</option>
          </select>
        </td>
        <td className="text-capitalize" >
          {warbandPart.selectedWarriorName ? warbandPart.selectedWarriorName : '-'}
        </td>
      </tr>
    );
  });

  let secondaryWarbandPositionCounter = 0;
  let secondaryWarbandTableRows = secondaryWarbandPlan.map((warbandPart) => {
    secondaryWarbandPositionCounter++;
    let warbandPartPosition = secondaryWarbandPositionCounter;
    return (
      <tr key={warbandPart.position}>
        <th>{warbandPartPosition}</th>
        <td>{warbandPart.warriorType}</td>
        <td>
          <select
            className="form-select"
            value={warbandPart.warriorLevel}
            disabled={secondaryWarbandPlanReady}
            onChange={(event) =>
              changeWarriorLevelSecondaryWarband(warbandPartPosition, event.target.value)
            }
          >
            <option value="4">Elite Champion</option>
            <option value="5">Legend</option>
            <option value="3">Champion</option>
            <option value="2">Soldier</option>
            <option value="1">Recruit</option>
          </select>
        </td>
        <td>-</td>
      </tr>
    );
  });

  return (
    <div>
      <h3>Tribe manage</h3>
      <div className="mt-4">
        <div className="warband-info-container">
          <h5>Primary Warband</h5>
          <div>
            <div className="warband-table-container">
            {primaryWarbandPlanReady ? (<div className="text-success fw-bold" >
                Plan is ready
              </div> ) : ('')}
              <div className="mb-3">
                Budget is available for 5 Legend, 5 Elite Champion
              </div>

              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Warrior Type</th>
                    <th scope="col">Warrior Level</th>
                    <th scope="col">Warrior</th>
                  </tr>
                </thead>
                <tbody>{primaryWarbandTableRows}</tbody>
              </table>
            </div>
          </div>
          <div>
            <button className="btn btn-dark" onClick={() => saveWarbandPlan(1)}>
              Ready
            </button>
          </div>
        </div>

        <div className="warband-info-container mt-3 ">
          <h5>Secondary Warband</h5>
          <div>
            <div className="warband-table-container">          
              {secondaryWarbandPlanReady ? (<div className="text-success fw-bold" >
                Plan is ready
              </div> ) : ('')} 
              <div className="mb-3">
                Budget is available for 5 Elite Champion, 5 Champion
              </div>
              

              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Warrior Type</th>
                    <th scope="col">Warrior Level</th>
                    <th scope="col">Warrior</th>
                  </tr>
                </thead>
                <tbody>{secondaryWarbandTableRows}</tbody>
              </table>
            </div>
          </div>
          <div>
            <button className="btn btn-dark" onClick={() => saveWarbandPlan(2)}>
              Ready
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
