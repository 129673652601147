import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CharacterMenu } from "./components/CharacterMenu";
import { Header } from "./components/Header";
import { Inventory } from "./components/Inventory/Inventory";
import { Footer } from "./components/Footer";
import { Main } from "./components/Main/Main";
import { Home } from "./components/Main/Home";
import CharacterContext, {
  CharacterContextProvider,
} from "./store/CharacterContext";
import { useContext, useEffect, useState } from "react";
import UserContext, { UserContextProvider } from "./store/UserContext";
import GeneralContext from "./store/GeneralContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingInformation } from "./components/General/LoadingInformation";


function App() {
  const [generalInformation, setgeneralInformation] = useState({
    characterName: "Warrior",
    level: 2,
  });

  useEffect(() => {
    //notify("Hello");

  },[])


  const notify = () =>
    toast.info("🦄 Wow so easy!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  

  return (
    <div>
      <UserContextProvider>
        <GeneralContext.Provider value={generalInformation}>
          <CharacterContextProvider>
            <Header />
            <Main />
            {/* <Footer /> */}
            <ToastContainer />
            <LoadingInformation/>
          </CharacterContextProvider>
        </GeneralContext.Provider>
      </UserContextProvider>
    </div>
  );
}

export default App;
